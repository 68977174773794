<template>
  <div>
    <vx-card class="sign-up-details mt-8">
      <div class="card-title">
        <h2>Sign Up</h2>
      </div>
      <!-- Tabing for Sign Up process -->
      <vs-tabs :value="activeTab">
        <!-- Tab 1 (Step 1 - Service Selection) -->
        <vs-tab label="Step 1 - Service Selection" @click="activeTab = 0">
          <div class="basepackage">
            <vx-card class="signup-card" title="Select Plan">
              <p class="text-muted" v-if="recommendedPlan">
                Thank you for choosing Visual Visitor’s +Person Web ID! We’re so happy you’re here. Based on the number of successful identifications made during your free trial, we recommend {{ recommendedPlan.PlanName }} that includes {{ recommendedPlan.Identifications }} monthly identifications. If you feel a larger plan is meant for you, please contact our <a
                  href="https://www.visualvisitor.com/meet-product-specialist/"
                  class="text-underline cursor-pointer"
                  target="_blank"
                  >product specialist</a>.  If you’re ready to get started go ahead and select your plan below. Click on next and enter your billing information.
              </p>
              <i class="text-muted">Please note plans can be upgraded or downgraded as needed inside the billing menu.</i>
              <vs-table class="signup-table" :data="availablePlans">
                <template #thead>
                  <vs-th> </vs-th>
                  <vs-th> Plan </vs-th>
                  <vs-th> Recommend Plan </vs-th>
                  <vs-th> Monthly Price </vs-th>
                  <vs-th> Monthly Identifications Included </vs-th>
                </template>
                <template slot-scope>
                  <vs-tr :key="i" v-for="(tr, i) in availablePlans" :data="tr">
                    <vs-td>
                      <vs-radio :vs-value="tr.PlanId" v-model="planId" @input="selectedPlanObj = tr"></vs-radio>
                    </vs-td>
                    <vs-td>
                      {{ tr.PlanName }}
                    </vs-td>
                    <vs-td>
                      {{ tr.RecommendedPlan ? 'Recommended'  : '' }}
                    </vs-td>
                    <vs-td>
                      ${{ currencyFormatter(tr.Price) }}
                    </vs-td>
                    <vs-td>
                      {{ tr.Identifications | tableRecordNumberFormatter }}
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
              <div class="mt-3">
                <p>
                  For larger plans please
                  <a
                    href="https://www.visualvisitor.com/meet-product-specialist/"
                    class="text-underline cursor-pointer"
                    target="_blank"
                    >Contact Sales</a
                  >
                </p>
              </div>
            </vx-card>
          </div>
        </vs-tab>
        <!-- Tab 2 (Step 2 - Billing Information) -->
        <vs-tab label="Step 2 - Billing Information" @click="activeTab = 1">
          <form data-vv-scope="signUpForm" class="signup-form">
            <div class="vx-row">
              <div class="vx-col md:w-1/3 w-full mb-6">
                <vs-input
                  class="w-full"
                  label="Name on Card"
                  v-model="billingNameOnCard"
                  name="billingNameOnCard"
                  v-validate="'required'"
                />
                <span class="text-danger text-sm">{{
                  errors.first("signUpForm.billingNameOnCard")
                }}</span>
              </div>
              <div class="vx-col md:w-1/3 w-full mb-6">
                <span class="vs-input--label">Card Type</span>
                <v-select
                  v-model="billingCardType"
                  :options="billingCardTypeOptions"
                  :clearable="false"
                />
              </div>
              <div class="vx-col md:w-1/3 w-full mb-6">
                <vs-input
                  class="w-full"
                  label="Credit Card Number"
                  v-model="billingCardNumber"
                  name="billingCardNumber"
                  v-validate="'required|credit_card'"
                />
                <span
                  class="text-danger text-sm"
                  v-show="billingCardNumberError"
                  >{{ billingCardNumberError }}</span
                >
                <span class="text-danger text-sm">{{
                  errors.first("signUpForm.billingCardNumber")
                }}</span>
                <p>Please use numbers only, no spaces or dashes.</p>
              </div>
              <div class="vx-col lg:w-1/3 w-full mb-6">
                <span class="block"
                  ><label class="vs-input--label"
                    >Credit Card Expiration</label
                  ></span
                >
                <div class="card-date">
                  <v-select
                    class="w-full float-left"
                    v-model="billingCardExpMonth"
                    :options="billingCardExpMonthOptions"
                    :clearable="false"
                  />
                  <span class="text-danger" v-if="!checkBillingMonthYear()">
                    Expiry month is less than current month so please select
                    appropriate month
                  </span>
                </div>
                <span class="date-arrow"> / </span>
                <div class="card-date">
                  <v-select
                    class="w-full float-left"
                    v-model="billingCardExpYear"
                    :options="billingCardExpYearOptions"
                    :clearable="false"
                  />
                </div>
              </div>
              <div class="vx-col md:w-1/3 w-full mb-6">
                <vs-input
                  label="Billing Address 1"
                  v-model="billingAddress1"
                  name="billingAddress1"
                  v-validate="'required'"
                />
                <span class="text-danger text-sm">{{
                  errors.first("signUpForm.billingAddress1")
                }}</span>
              </div>
              <div class="vx-col md:w-1/3 w-full mb-6">
                <vs-input label="Billing Address 2" v-model="billingAddress2" />
              </div>
              <div class="vx-col md:w-1/3 w-full mb-6">
                <vs-input
                  class="w-full"
                  label="City"
                  v-model="billingCity"
                  name="billingCity"
                  v-validate="'required'"
                />
                <span class="text-danger text-sm">{{
                  errors.first("signUpForm.billingCity")
                }}</span>
              </div>
              <div class="vx-col md:w-1/3 w-full mb-6">
                <vs-input
                  class="w-full"
                  label="State"
                  v-model="billingState"
                  name="billingState"
                  v-validate="'required'"
                />
                <span class="text-danger text-sm">{{
                  errors.first("signUpForm.billingState")
                }}</span>
              </div>
              <div class="vx-col md:w-1/3 w-full mb-6">
                <vs-input
                  class="w-full"
                  label="Zip"
                  v-model="billingZip"
                  name="billingZip"
                  v-validate="'required'"
                />
                <span class="text-danger text-sm">{{
                  errors.first("signUpForm.billingZip")
                }}</span>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  label="Billing Email"
                  v-model="billingEmail"
                  name="billingEmail"
                  v-validate="'required|email'"
                />
                <span class="text-danger text-sm">{{
                  errors.first("signUpForm.billingEmail")
                }}</span>
              </div>
            </div>
          </form>
        </vs-tab>
        <!-- Tab 3 (Step 3 - Confirm and Complete) -->
        <vs-tab label="Step 3 - Confirm and Complete" @click="activeTab = 2">
          <div class="vx-row pb-8">
            <div class="vx-col xl:w-2/3 col-cus-12 w-full">
              <vx-card>
                <div class="card-title">
                  <h2 class="w-auto py-2">Billing Summary</h2>
                </div>
                <vs-table class="table-list" :data="[1, 2]">
                  <template slot="thead">
                    <vs-th sort-key="service">Service</vs-th>
                    <vs-th sort-key="notes">Notes</vs-th>
                    <vs-th sort-key="qty">Qty</vs-th>
                    <vs-th sort-key="price">Price</vs-th>
                    <vs-th sort-key="subtotal">Sub Total</vs-th>
                  </template>
                  <template slot-scope="{ data }">
                    <tbody>
                      <vs-tr>
                        <vs-td>
                          <p>{{ selectedPlanObj.PlanName }}</p>
                        </vs-td>
                        <vs-td>
                          <p>
                            <!-- Includes 3 user accounts, price does not include
                            page view overage. -->
                            {{'Monthly'}}
                          </p>
                        </vs-td>
                        <vs-td>
                          <p>1</p>
                        </vs-td>
                        <vs-td>
                          <p>
                            ${{ selectedPlanObj.Price }}
                          </p>
                        </vs-td>
                        <vs-td>
                          <p class="text-secondary">
                            ${{ selectedPlanObj.Price }}
                          </p>
                        </vs-td>
                      </vs-tr>
                    </tbody>
                    <tfoot>
                      <vs-tr>
                        <vs-td></vs-td>
                        <vs-td></vs-td>
                        <vs-td></vs-td>
                        <vs-td>
                          <p class="total-font font-medium">Total</p>
                        </vs-td>
                        <vs-td>
                          <span class="total-font text-secondary"
                            >${{ selectedPlanObj.Price }}</span
                          >
                        </vs-td>
                      </vs-tr>
                    </tfoot>
                  </template>
                </vs-table>
              </vx-card>
            </div>
            <div class="vx-col xl:w-1/3 col-cus-12 w-full mt-cus-8">
              <vx-card class="credit-card">
                <div class="card-title">
                  <h2 class="w-auto py-2">Credit Card Information</h2>
                </div>
                <div class="credit-card-design bg-primary-gradient">
                  <span class="pay-img">
                    <img
                      v-show="billingCardType == 'American Express'"
                      src="../assets/images/american-express.png"
                    />
                    <img
                      v-show="billingCardType == 'MasterCard'"
                      src="../assets/images/mastercard.png"
                    />
                    <img
                      v-show="billingCardType == 'Visa'"
                      src="../assets/images/visa.png"
                    />
                  </span>
                  <span class="card-no">{{ billingCardNumber }}</span>
                  <div class="name-date">
                    <h5 class="float-left">
                      <span>Card Name</span>{{ billingNameOnCard }}
                    </h5>
                    <h5 class="float-right">
                      <span>Card Expiration</span>{{ billingCardExpMonth }}/{{
                        billingCardExpYear
                      }}
                    </h5>
                  </div>
                </div>
              </vx-card>
            </div>
          </div>
        </vs-tab>
      </vs-tabs>
      <!-- Cancel and navigation button -->
      <div class="flex justify-end w-full mt-6 flex-wrap">
        <vs-button
          color="grey"
          type="border"
          class="mr-0 sm:mr-4 mb-2 sm:mb-0 w-full sm:w-auto grey-btn"
          @click="cancelSignUp()"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
        <vs-button
          v-if="activeTab == 0"
          :disabled="!planId"
          color="primary"
          type="filled"
          class="mr-0 sm:mr-4 mb-2 sm:mb-0 w-full sm:w-auto"
          @click="activeTab = 1"
          >Next</vs-button
        >
        <vs-button
          v-if="activeTab == 1"
          color="primary"
          type="filled"
          class="mr-0 sm:mr-4 mb-2 sm:mb-0 w-full sm:w-auto"
          @click="prevalidateBilling()"
          >Next</vs-button
        >
        <vs-button
          v-if="activeTab == 2"
          color="primary"
          type="filled"
          class="mr-0 sm:mr-4 mb-2 sm:mb-0 w-full sm:w-auto"
          @click="processSignup()"
          >Complete Purchase</vs-button
        >
      </div>
    </vx-card>

    <!-- Fail transaction message popup -->
    <vs-popup class="transaction-popup" :active.sync="errorPopup">
      <div class="transaction-details">
        <div class="popup-icon"><img src="../assets/images/error.svg" /></div>
        <h2>Transaction Failed</h2>
        <p>Please verify your credit card information and try again.</p>
        <vs-button
          @click="errorPopup = false"
          color="grey"
          type="border"
          class="grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>
    <!-- Transaction complete message popup -->
    <vs-popup class="transaction-popup" :active.sync="transactionSuccess">
      <div class="transaction-details">
        <div class="popup-icon"><img src="../assets/images/checked.svg" /></div>
        <h2>Transaction Complete</h2>
        <p>Thank you for your business!</p>
        <vs-button
          @click="redirectToDashboard()"
          color="grey"
          class="grey-btn"
          type="border"
          >{{ LabelConstant.buttonLabelClose }}</vs-button
        >
      </div>
    </vs-popup>
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
import { Validator } from "vee-validate";
import store from "../store/store";
import { BButtonGroup, BButton } from "bootstrap-vue";

const dict = {
  custom: {
    billingNameOnCard: {
      required: "A Name is required",
    },
    billingCardNumber: {
      required: "A Credit Card Number is required",
      credit_card: "Please enter valid Credit Card Number.",
    },
    billingAddress1: {
      required:
        "Billing address must be more than 1 and less than 300 characters",
    },
    billingCity: {
      required: "A City is required",
    },
    billingState: {
      required: "A State is required",
    },
    billingZip: {
      required: "A Zip is required",
    },
    billingEmail: {
      required: "An Email is required",
      email: "Please enter a valid email address",
    },
  },
};
Validator.localize("en", dict);

export default {
  components: {
    VxCard,
    FormWizard,
    TabContent,
    vSelect,
    BButtonGroup,
    BButton,
  },
  data() {
    return {
      activeTab: 0,
      planId: false,
      selectedPlanObj:null,
      recommendedPlan: null,
      availablePlans: [],
      
      billingNameOnCard: null,
      billingCardType: "Visa",
      billingCardNumber: null,
      billingCardExpMonth: "1",
      billingCardExpYear: new Date().getFullYear(),
      billingAddress1: null,
      billingAddress2: null,
      billingCity: null,
      billingState: null,
      billingZip: null,
      billingEmail: null,

      billingCardTypeOptions: ["Visa", "MasterCard", "American Express"],
      billingCardExpMonthOptions: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      billingCardExpYearOptions: [],
      billingCardNumberError: null,

      errorPopup: false,
      transactionSuccess: false
    };
  },
  mounted() {
    this.getYears();
    this.getSignupInfo();
  },
  computed: {
  
  },
  methods: {
    getSignupInfo(){
      this.$vs.loading();
      this.axios.get("/ws/ConsumerBilling/GetSignupInfo").then((response) => {
        let data = response.data
        this.availablePlans = data.AvailablePlans
        for (let i = 0; i < data.AvailablePlans.length; i++) {
          if(data.AvailablePlans[i].RecommendedPlan)
          this.recommendedPlan = data.AvailablePlans[i]        
        }
        this.$vs.loading.close();
      }).catch((e) => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    //Cancel Sign up process
    cancelSignUp() {
      this.activeTab = 0;
      this.planId = false;
      this.selectedPlanObj = null;
      this.billingCardType = "Visa";
      this.billingCardExpMonth = "1";
      this.billingCardExpYear = new Date().getFullYear();
      this.billingCardNumber = null;
      this.billingNameOnCard = null;
      this.billingAddress1 = null;
      this.billingAddress2 = null;
      this.billingCity = null;
      this.billingState = null;
      this.billingZip = null;
      this.billingEmail = null;
      setTimeout(() => {
        this.errors.clear("signUpForm");
      }, 20);
    },
    //Validate the billing details
    prevalidateBilling() {
      this.$validator.validateAll("signUpForm").then((result) => {
        if (result && this.checkBillingMonthYear()) {
          let prevalidateBillingParams = {
            PlanId: this.planId,
            Billing_Card_Type: this.billingCardType,
            Billing_Card_Exp_Month: this.billingCardExpMonth,
            Billing_Card_Exp_Year: this.billingCardExpYear,
            Billing_Card_Number: this.billingCardNumber,
            Billing_Name_On_Card: this.billingNameOnCard,
            Billing_Address1: this.billingAddress1,
            Billing_Address2: this.billingAddress2,
            Billing_City: this.billingCity,
            Billing_State: this.billingState,
            Billing_Zip: this.billingZip,
            Billing_Email: this.billingEmail,
          };
          this.$vs.loading();
          this.axios
            .post("/ws/Signup/PrevalidateBilling", prevalidateBillingParams)
            .then(() => {
              this.activeTab = 2;
              this.billingCardNumberError = null;
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.showError(e);
              this.$vs.loading.close();
              if (e.response.data.Errors[0].Key == "Billing_Card_Number") {
                this.billingCardNumberError = e.response.data.Errors[0].Message;
              }
              this.$vs.notify({
                title: "Error",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle",
                position: "top-right",
                time: 4000,
              });
            });
        }
      });
    },
    //Proceed for sign-up process
    processSignup() {
      let processSignupParams = {
        PlanId: this.planId,
        Billing_Card_Type: this.billingCardType,
        Billing_Card_Exp_Month: this.billingCardExpMonth,
        Billing_Card_Exp_Year: this.billingCardExpYear,
        Billing_Card_Number: this.billingCardNumber,
        Billing_Name_On_Card: this.billingNameOnCard,
        Billing_Address1: this.billingAddress1,
        Billing_Address2: this.billingAddress2,
        Billing_City: this.billingCity,
        Billing_State: this.billingState,
        Billing_Zip: this.billingZip,
        Billing_Email: this.billingEmail,
      };
      this.$vs.loading();
      this.axios
        .post("/ws/Signup/ProcessConsumerSignup", processSignupParams)
        .then(() => {
          this.billingCardNumberError = null;

          let uid = this.$route.params.id;
          let VVtoken = localStorage.getItem("VVtoken");
          let token = JSON.parse(VVtoken);
          token[uid].isActiveFreeTrial = false;
          token[uid].isExpiredFreeTrial = false;
          localStorage.setItem("VVtoken", JSON.stringify(token));
          store.dispatch("updateAuthToken", token);
          this.transactionSuccess=true
          this.getInterfaceOptions();
          // this.$router.push({
          //   path: "/pages/thankyou/uid/" + uid,
          //   query: { amount: 0 },
          // });
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.showError(e);
          this.$vs.loading.close();
          this.activeTab = 1;
          this.errorPopup = true;
          if (e.response.data.Errors[0].Key == "Billing_Card_Number") {
            this.billingCardNumberError = e.response.data.Errors[0].Message;
          }
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
        });
    },
    getYears() {
      let cyArray = [];
      let startYear = new Date().getFullYear();
      let endYear = new Date().getFullYear() + 10;
      for (let index = startYear; index < endYear; index++) {
        cyArray.push(index);
      }
      this.billingCardExpYearOptions = cyArray;
    },
    checkBillingMonthYear() {
      let month = new Date().getMonth();
      let cy = new Date().getFullYear();
      if (cy == this.billingCardExpYear) {
        if (this.billingCardExpMonth < month + 1) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    getInterfaceOptions() {
      let url = 'Account/GetInterfaceOptions';
      this.axios.get(url).then(response => {
        let data = response.data;

        let uid = this.$route.params.id;
        let VVtoken = localStorage.getItem("VVtoken");
        let token = JSON.parse(VVtoken);
        token[uid].menuItems = data.MenuItems
        //token[uid].themeDetail = data.DashboardThemeV2
        token[uid].isReseller = data.Reseller
        token[uid].isActiveFreeTrial = data.IsActiveFreeTrial
        token[uid].isExpiredFreeTrial = data.FreeTrialIsExpired
        token[uid].resellerIsLegacyOnly = data.Reseller_Is_Legacy_Only
        token[uid].showBillingV2 = data.ShowBillingV2
        token[uid].showGuidedTour = data.ShowGuidedTour
        token[uid].userDetails = data.UserInformation
        localStorage.setItem("VVtoken", JSON.stringify(token));
        store.dispatch('updateAuthToken', token)

        this.$store.commit('UPDATE_IS_RESELLER', data.Reseller)
        this.$store.commit('UPDATE_MAX_TALL_FREE_NUMBERS', data.MaxTollFreeNumbers)
        this.$store.commit('UPDATE_MAX_REVIEW_LOCATIONS', data.MaxReviewLocations)
        this.$store.commit('UPDATE_MAX_USERS', data.MaxUsers)
        this.$store.commit('UPDATE_ENABLE_ZAPIER_INTEGRATION', data.EnableZapierIntegration)
        this.$store.commit('UPDATE_LOGGED_IN_ACCOUNT_LOGO', data.Logo)
        this.$store.commit('UPDATE_USER_DETAILS', data.UserInformation)

        this.$store.commit('UPDATE_SHOW_CALL_TRACKING_BILLING_SUMMARY_FOR_RESELLERS', data.ShowCallTrackingBillingSummaryForResellers)
        this.$store.commit('UPDATE_COMPANY_ACCOUNT_ID', data.CompanyAccountId)
      }).catch(e => {
          this.showError(e);
      });
    },
    //After successfull transaction it will redirect to Dashboard
    redirectToDashboard() {
      this.transactionSuccess = false;
      this.$router.push({
        name: "ConsumerDashboard",
        params: { reload: true },
      });
    },
  },
};
</script>
